 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 400;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYlK-4E4Q.woff2) format('woff2');
     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 400;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYsK-4E4Q.woff2) format('woff2');
     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 400;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYnK-4E4Q.woff2) format('woff2');
     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 400;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYmK-4E4Q.woff2) format('woff2');
     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 400;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 500;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYlK-4E4Q.woff2) format('woff2');
     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 500;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYsK-4E4Q.woff2) format('woff2');
     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 500;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYnK-4E4Q.woff2) format('woff2');
     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 500;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYmK-4E4Q.woff2) format('woff2');
     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 500;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 600;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYlK-4E4Q.woff2) format('woff2');
     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 600;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYsK-4E4Q.woff2) format('woff2');
     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 600;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYnK-4E4Q.woff2) format('woff2');
     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 600;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYmK-4E4Q.woff2) format('woff2');
     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 600;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 700;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYlK-4E4Q.woff2) format('woff2');
     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 700;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYsK-4E4Q.woff2) format('woff2');
     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 700;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYnK-4E4Q.woff2) format('woff2');
     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 700;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYmK-4E4Q.woff2) format('woff2');
     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 700;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 800;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYlK-4E4Q.woff2) format('woff2');
     unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 800;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYsK-4E4Q.woff2) format('woff2');
     unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 800;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYnK-4E4Q.woff2) format('woff2');
     unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 800;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYmK-4E4Q.woff2) format('woff2');
     unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
     font-display: swap;
 }
 @font-face {
     font-family: 'Exo 2';
     font-style: normal;
     font-weight: 800;
     src: url(https://fonts.gstatic.com/s/exo2/v15/7cHmv4okm5zmbtYoK-4.woff2) format('woff2');
     unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
     font-display: swap;
 }
 body {
     overflow-x: hidden
 }
 .hiddenlink {
    color: #000 !important;
 }
 body,
 html {
     height: 100%
 }
 abbr,
 acronym,
 address,
 applet,
 aside,
 b,
 big,
 blockquote,
 body,
 caption,
 center,
 cite,
 code,
 dd,
 del,
 dfn,
 dl,
 dt,
 em,
 fieldset,
 font,
 footer,
 form,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6,
 header,
 html,
 i,
 iframe,
 img,
 ins,
 kbd,
 label,
 legend,
 li,
 nav,
 object,
 ol,
 p,
 pre,
 q,
 s,
 samp,
 section,
 small,
 span,
 strike,
 strong,
 sub,
 sup,
 table,
 tbody,
 td,
 tfoot,
 th,
 thead,
 tr,
 tt,
 u,
 ul,
 var {
     background: 0 0;
     border: 0;
     margin: 0;
     outline: 0;
     padding: 0;
     text-decoration: none
 }
 * {
     box-sizing: border-box
 }
 body {
     font-family: 'Exo 2', sans-serif;
     font-size: 15px;
     color: #363636;
     background: #fff
 }
 input[type=Password],
 input[type=text] {
     margin: 0;
     padding: 0;
     border: 1px solid #ddd;
     background: #fff;
     font-size: 15px;
     color: #000;
     padding: 7px;
     border-radius: 3px;
     font-family: 'Exo 2', sans-serif;
     -webkit-appearance: none
 }
 .h1,
 .h2,
 .h3,
 .h4,
 .h5,
 .h6,
 h1,
 h2,
 h3,
 h4,
 h5,
 h6 {
     font-family: 'Exo 2', sans-serif
 }
 textarea {
     -webkit-appearance: none
 }
 select,
 textarea {
     margin: 0;
     padding: 0;
     border: 1px solid #ddd;
     background: #fff;
     font-size: 15px;
     color: #000;
     padding: 4px;
     border-radius: 3px;
     font-family: 'Exo 2', sans-serif;
     resize: none
 }
 h1,
 h2,
 h3,
 h4,
 h5 {
     font-weight: 400
 }
 p {
     margin: 0 0 22px 0;
     padding: 0;
     line-height: 22px
 }
 img {
     border: none;
     outline: 0
 }
 a {
     color: #ff5500;/*#ff6e00;*/
     text-decoration: none;
     outline: 0;
     -webkit-transition: .5s;
     -moz-transition: .5s;
     -ms-transition: .5s;
     -o-transition: .5s;
     transition: .5s
 }
 a:hover {
     color: #4a0;
     text-decoration: none;
     outline: 0
 }
 a:focus {
     outline: 0
 }
 :focus {
     outline: 0
 }
 .clear {
     clear: both
 }
 .relative {
     position: relative
 }
 .fl-left {
     float: left !important
 }
 .fl-right {
     float: right !important
 }
 .v-midd {
     vertical-align: middle !important
 }
 #main {
     display: block;
     float: left;
     width: 100%
 }
 #main-page {
     display: block;
     float: left;
     width: 100%
 }
 #top-part {
     display: block;
     float: left;
     width: 100%
 }
 .navbar-nav {
     float: right
 }
 .navbar-collapse {
     padding: 0
 }
 .navbar-inverse {
     background: #fff;
     border: none;
     box-shadow: 0 0 3px rgba(0, 0, 0, .3)
 }
 .navbar-inverse .navbar-nav>li>a {
     padding: 29px 8px;
     font-size: 13px;
     color: #6e6e6e;
     text-transform: uppercase;
     font-weight: 700;
     border-bottom: 2px solid #fff
 }
 .navbar-inverse .navbar-nav>li>a.active,
 .navbar-inverse .navbar-nav>li>a:focus,
 .navbar-inverse .navbar-nav>li>a:hover {
     color: #ff6e00;
     border-color: #ff6e00
 }
 .navbar-nav>li a.menu-active {
     color: #ff6e00 !important;
     border-color: #ff6e00 !important
 }
 .navbar-nav>li a.menu1-active {
     color: #ff6e00 !important;
     border-color: #ff6e00 !important
 }
 .navbar-inverse .navbar-nav>li>a:hover .caret {
     color: #ff6e00
 }
 .navbar-inverse .navbar-nav>.open>a,
 .navbar-inverse .navbar-nav>.open>a:focus,
 .navbar-inverse .navbar-nav>.open>a:hover {
     color: #ff6e00;
     background: 0 0
 }
 .navbar-brand {
     padding: 10px
 }
 .dropdown-menu {
     border: none;
     padding: 0
 }
 .navbar-right .dropdown-menu {
     left: 0;
     right: auto
 }
 .buynow a {
     width: 110px;
     display: block;
     border-radius: 3px;
     background: #fff none repeat scroll 0 0;
     border: 1px solid #fc4d00 !important;
     padding: 7px 0 !important;
     text-align: center;
     color: #fc4d00 !important;
     margin-top: 22px
 }
 .navbar-nav>li:hover .sub-menu {
     display: block
 }
 .navbar-nav>li:hover .sub-menu li:hover .sub-menu1 {
     display: block
 }
 .buynow a:focus,
 .buynow a:hover {
     background: #fc4d00 none repeat scroll 0 0 !important;
     color: #fff !important
 }
 .dropdown-menu>li>a {
     padding: 10px 20px;
     color: #6e6e6e;
     font-size: 13px;
     text-transform: uppercase
 }
 .dropdown-menu>li>a:focus,
 .dropdown-menu>li>a:hover {
     background: #e4e4e4;
     color: #6e6e6e
 }
 .dropdown-menu>li:last-child>a:hover {
     border-radius: 0 0 4px 4px
 }
 #midd-part {
     display: block;
     float: left;
     width: 100%
 }
 .carousel {
     height: 592px
 }
 .active,
 .carousel-inner,
 .item {
     height: 100%
 }
 .fill {
     width: 100%;
     height: 100%;
     background-position: center;
     background-size: cover
 }
 /* .chat-now-btn {
     position: fixed;
     right: 0;
     z-index: 999
 }
 .enq-now-btn {
     position: fixed;
 } */
 .common_action_button_center {
    position: fixed;
    z-index: 999;
    right: 50px;
    top: 50%;
    transform-origin: top right;
    transform: rotate(-90deg) translatex(50%);
    -webkit-transform: rotate(-90deg) translatex(50%);
    -moz-transform: rotate(-90deg) translatex(50%);
    -ms-transform: rotate(-90deg) translatex(50%);
    -o-transform: rotate(-90deg) translatex(50%);
    gap: 10px;
    display: flex;
}
 .pricing-btn {
     position: fixed;
     top: 367px;
     right: 0;
     z-index: 999
 }
 .blink {
     animation: blink 2s infinite;
     -webkit-animation: blink 2s infinite
 }
 @keyframes blink {
     30% {
         opacity: 0
     }
 }
 .enq-now-btn {
    vertical-align: middle;
    display: inline-block;
 }
 .enq-now-btn img {
    max-width: initial;
 }
 .cubex-btn {
    padding: 16.5px 13px;
    background-color: #609afe;
    color: #fff;
    text-transform: uppercase;
    font-weight: 600;
    position: relative;
    top: auto;
    right: 0;
    border-radius: 4px 4px 0 0;
    backface-visibility: hidden;
    transform-origin: right bottom;
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s;
    display: inline-block;
    vertical-align: top;
    white-space:nowrap;
}
 .cubex-btn:hover {
     background-color: #0e48ac;
     color: #fff
 }
 .cubex-btn[title=CubeJekX2020] {
     background-color: #24a707;
     top: 400px
 }
 .cubex-btn[title=CubeJekX2020]:hover {
     background-color: #197206
 }
 body.floating-buttons .cubex-btn {
     top: 207px
 }
 .demo-right-button {
     position: fixed;
     right: 0;
     top: 99px;
     z-index: 999
 }
 .carousel-indicators {
     bottom: 40px;
     left: 0;
     right: 0;
     margin: auto
 }
 .carousel-caption {
     bottom: 100px;
     left: 0;
     right: 0;
     margin: auto
 }
 .carousel-caption h2 {
     font-size: 17px;
     color: #fff;
     font-weight: 700;
     text-transform: uppercase;
     text-shadow: 0 1px 2px rgba(0, 0, 0, .8)
 }
 h3.inner-heading {
     font-size: 27px;
     color: #000;
     font-weight: 600;
     margin-bottom: 20px
 }
 .section {
     padding: 50px 0
 }
 h3.page-heading.whitefont {
     color: #fff
 }
 h3.page-heading {
     font-size: 41px;
     font-weight: 700;
     color: #ff6f02;
     text-align: center;
     text-transform: uppercase
 }
 h3.page-heading:after {
     content: "";
     background: url(../images/hd-divider.png) no-repeat;
     width: 120px;
     height: 5px;
     margin: 15px auto 25px;
     display: block
 }
 p.hd-desc {
     font-size: 20px;
     text-align: center;
     line-height: 27px;
     margin-bottom: 40px
 }
 .gray-color-bg {
     margin: 0;
     padding: 0 0 50px;
     float: left;
     width: 100%;
     background: #f0f0f0
 }
 .gray-color-bg h3.page-heading {
     margin: 50px 0 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .widthauto {
     max-width: inherit !important
 }
 .viewdemo-btn {
     width: 50%;
     float: right;
     text-align: right
 }
 .viewdemo-btn a {
     border: 2px solid #000;
     padding: 0 20px;
     text-transform: uppercase;
     height: 45px;
     line-height: 42px;
     color: #000;
     font-weight: 700;
     display: inline-block
 }
 .viewdemo-btn a:hover {
     background: #000;
     color: #fff
 }
 .inner-banner {
     margin-top: 80px;
     background-size: cover;
     height: auto;
     padding: 22px 0 28px 0;
     background-color: #000;
 }
 h1.page-heading {
     font-size: 41px;
     color: #fff;
     text-align: center;
     font-weight: 700
 }
 h1.page-heading span {
     font-size: 19px;
     font-weight: 300;
     margin-top: 5px;
     color: #ff6f02;
     display: block
 }
 .our-products-bottom-button {
     margin: 0;
     padding: 30px 0;
     float: left;
     width: 100%;
     text-align: center
 }
 .our-products-bottom-button a {
     vertical-align: top;
     margin: 0 10px;
     padding: 12px 0;
     float: none;
     display: inline-table;
     font-size: 20px;
     text-transform: uppercase;
     color: #ff6f02;
     border: 2px solid #ff6f02;
     border-radius: 5px;
     font-weight: 700;
     width: 210px
 }
 .our-products-bottom-button a:hover {
     border-color: #4a0;
     color: #4a0
 }
 .our-products-bottom-button h5 {
     display: inline-table
 }
 #bott-part {
     display: block;
     background: #fff;
     float: left;
     width: 100%;
     border-top: 1px solid #cecece
 }
 #bott-part h3.page-heading:after {
     content: "";
     background: #fc4d00;
     width: 75px;
     height: 1px;
     margin: 15px auto 30px;
     display: block
 }
 #bott-part b.page-heading:after {
     content: "";
     background: #fc4d00;
     width: 75px;
     height: 1px;
     margin: 15px auto 30px;
     display: block
 }
 footer {
     font-family: "Exo 2";
 }
 .footer-address-img {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%;
     text-align: center
 }
 .footer-address-img img {
     min-width: 100%;
     max-width: 100%;
 }
 .footer-slogen {
     float: left;
     font-size: 16px;
     font-weight: 600;
     margin: 7px 0;
     padding: 0;
     text-align: center;
     text-transform: none;
     width: 100%
 }
 .bott-part1 {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .bott-part1 img {
     width: 100%
 }
 .bott-part2 {
     padding: 30px 0 0;
     font-weight: 300;
     float: left;
     width: 100%
 }
 .bott-part3 {
     padding: 30px 0;
     text-align: center;
     font-weight: 300;
     color: #777776;
     position: relative;
     float: left;
     width: 100%
 }
 .bott-up-arr {
     position: absolute;
     top: -25px;
     left: 0;
     right: 0;
     margin: auto
 }
 #scroll {
     position: fixed;
     right: 10px;
     bottom: 70px;
     cursor: pointer;
     width: 40px;
     height: 40px;
     background: #161616 none repeat scroll 0 0;
     border: 2px solid #fff;
     box-shadow: 0 0 6px #777;
     text-indent: -9999px;
     display: none;
     -webkit-border-radius: 60px;
     -moz-border-radius: 60px;
     border-radius: 60px;
     z-index: 99999
 }
 #scroll span {
     position: absolute;
     top: 50%;
     left: 50%;
     margin-left: -8px;
     margin-top: -12px;
     height: 0;
     width: 0;
     border: 8px solid transparent;
     border-bottom-color: #fff
 }
 #scroll:hover {
     background-color: #e74c3c;
     opacity: 1;
     filter: "alpha(opacity=100)";
     -ms-filter: "alpha(opacity=100)"
 }
 .bott-cnt {
     display: block;
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .bott-cnt h3 {
     font-size: 41px;
     font-weight: 600;
     color: #ff6f02;
     text-align: center;
     text-transform: uppercase
 }
 .bott-cnt h3:after {
     content: "";
     background: #fff;
     width: 50px;
     height: 1px;
     margin: 15px auto 25px;
     display: block
 }
 .bott-cnt b.page-heading {
     font-size: 41px;
     font-weight: 600;
     color: #fc4d00;
     text-align: center;
     text-transform: uppercase;
     padding: 0;
     text-align: center;
     float: left;
     width: 100%
 }
 .bott-cnt b.page-heading:after {
     content: "";
     background: #fff;
     width: 50px;
     height: 1px;
     margin: 15px auto 25px;
     display: block
 }
 .bott-cnt h3 span {
     color: #8f8f8f;
     display: block;
     margin-top: 5px;
     font-size: 18px;
     font-weight: 300;
     text-transform: none;
     font-weight: 300
 }
 .bott-block1 {
     width: 277px;
     float: left;
     margin-right: 35px
 }
 .bott-block2 {
     width: 325px;
     float: left;
     margin-right: 25px
 }
 .bott-block3 {
     width: 270px;
     float: left
 }
 .bott-block4 {
     width: 320px;
     float: right
 }
 .bott-block4 span {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .bott-block4 .blog-text {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .bott-block4 h6 {
     margin: 3px 0 0 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .bott-block4 h6 a {
     margin: 0;
     padding: 0;
     float: right;
     font-weight: 500;
     font-size: 16px
 }
 .bott-block4 ul {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .bott-block4 ul li {
     margin: 0 0 15px 0;
     padding: 0;
     float: left;
     width: 100%;
     display: block
 }
 .bott-block4 ul li .footer-blog-left {
     margin: 0;
     padding: 0;
     float: left;
     width: 72px;
     height: 53px;
     border: 1px solid #ddd
 }
 .bott-block4 ul li .footer-blog-left b {
     margin: 0;
     padding: 0;
     float: left;
     width: 72px;
     height: 53px;
     overflow: hidden
 }
 .bott-block4 ul li .footer-blog-left b img {
     width: 100%
 }
 .bott-block4 ul li .footer-blog-right {
     margin: 0 0 0 10px;
     padding: 0;
     float: left
 }
 .bott-block4 ul li .footer-blog-right b {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%;
     font-weight: 400
 }
 .bott-block4 ul li .footer-blog-right b a {
     color: #2a2a2a
 }
 a.fbimg {
     display: inline-block
 }
 a.fbimg img {
     max-width: 100%;
     image-rendering: -webkit-optimize-contrast
 }
 .bott-block4 ul li .footer-blog-right b a:hover {
     color: #50b211
 }
 .bott-logo {
     margin: 0 0 22px 10px;
     padding: 0;
     width: 198px
 }
 .bott-logo img {
     margin: 0;
     padding: 0;
     width: 100%
 }
 .bott-logo:after {
     content: "";
     background: #fff;
     width: 30px;
     height: 1px;
     margin: 15px auto 15px;
     display: block
 }
 .timing {
     margin-bottom: 20px;
     display: block
 }
 .bott-block2 p {
     font-size: 14px;
     color: #a0a0a0;
     margin-bottom: 7px;
     line-height: normal
 }
 .bott-block2 p span {
     font-size: 20px;
     color: #eb6f12;
     font-weight: 500
 }
 .bott-block2 p a {
     font-size: 15px;
     color: #bbb
 }
 .bott-block2 p a:hover {
     font-size: 15px;
     color: #ff6e00
 }
 .bott-block3-l {
     width: 100%;
     float: left;
     margin: 0;
     padding: 0
 }
 .bott-block3-r {
     width: 100%;
     float: left;
     margin: 0;
     padding: 0
 }
 .bott-flag {
     float: left;
     margin-right: 15px
 }
 .bott-add {
     float: left;
     color: #545454;
     width: 80%
 }
 .bott-add span {
     font-size: 18px;
     font-weight: 600;
     color: #44ac00
 }
 .bott-add em {
     font-style: normal;
     font-size: 14px;
     color: #a0a0a0;
     font-weight: 300
 }
 .recently-moved {
     margin: 0 0 5px;
     padding: 0;
     float: left;
     width: 100%;
     text-align: center
 }
 h4.page-heading {
     font-size: 18px;
     color: #2a2a2a;
     font-weight: 700;
     margin-bottom: 10px;
     text-transform: uppercase
 }
 h6.page-heading,
 strong.page-heading {
     font-size: 18px;
     color: #2a2a2a;
     font-weight: 700;
     margin-bottom: 10px;
     text-transform: uppercase;
     display: block;
     font-family: "Exo 2"
 }
 .bott-part3 {
     padding: 20px 0;
     text-align: center;
     font-weight: 500;
     color: #000;
     font-size: 14px;
     position: relative;
     border-top: 1px solid #cecece;
     line-height: 18px
 }
 .bott-up-arr {
     position: absolute;
     top: -26px;
     left: 0;
     right: 0;
     margin: auto
 }
 .bott-logo:after {
     content: "";
     background: #fff;
     width: 30px;
     height: 1px;
     margin: 15px auto 15px;
     display: block
 }
 .timing {
     margin-bottom: 20px;
     display: block
 }
 .bott-block2 p a {
     font-size: 15px;
     color: #44ac00
 }
 .bott-block2 p a:hover {
     font-size: 15px;
     color: #000
 }
 .bott-flag {
     float: left;
     margin-right: 15px
 }
 .bott-add {
     float: left;
     color: #545454;
     width: 76%
 }
 .bott-add span {
     font-size: 16px;
     font-weight: 700;
     color: #44ac00;
     text-transform: uppercase
 }
 .bott-add em {
     font-style: normal;
     font-size: 15px;
     color: #333;
     font-weight: 400;
     text-transform: none;
     float: left;
     width: 100%
 }
 .bott-add p {
     margin: 0 0 12px
 }
 .copyright {
    font-size: 13px;
    color: #000;
    float: left;
    width: 100%;
    margin-top: 20px;
 }
 .copyright b {
     color: #fc4d00;
     font-weight: 400;
     display: inline;
 }
 .copy-flex {
     display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
 .achievement-img {
     display: block;
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .achievement-desc {
     display: block;
     padding: 10px 0;
     font-size: 14px;
     margin: 0;
     float: left;
     width: 100%
 }
 .achievement-desc p {
     line-height: 20px
 }
 .achievement-desc h4 {
     font-size: 21px;
     font-weight: 700;
     color: #44ac00;
     margin-bottom: 7px
 }
 .achievement-sec {
     position: relative;
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .achievement-dott {
     position: absolute;
     top: -30px;
     right: 0
 }
 .achievement-dott a {
     width: 12px;
     height: 12px;
     display: inline-block;
     vertical-align: middle;
     background: #999;
     border-radius: 50%;
     margin-left: 5px
 }
 .achievement-dott a.active,
 .achievement-dott a:hover {
     background: #fc4d00
 }
 .chatnow {
     position: fixed;
     right: 0;
     top: 400px;
     z-index: 998;
     background: #4a0;
     border-radius: 4px 0 0 4px;
     color: #fff;
     height: auto;
     padding: 5px 0 5px 5px;
     width: 289px
 }
 .getfreequote {
     position: fixed;
     right: 0;
     top: 125px;
     z-index: 999
 }
 .getfreequote1 {
     position: fixed;
     right: 0;
     top: 150px;
     z-index: 998
 }
 .getfreequote2 {
     position: fixed;
     right: 0;
     top: 330px;
     z-index: 998
 }
 .arrow {
     float: right
 }
 .getfreequote1 {
     z-index: 999
 }
 .getfreequote {
     position: fixed;
     right: 0;
     top: 115px;
     z-index: 999;
     color: #000;
     padding: 0;
     width: 331px;
     height: 455px;
     border-radius: 4px 0 0 4px ;
     -webkit-border-radius: 4px 0 0 4px;
     -moz-border-radius: 4px 0 0 4px;
     -ms-border-radius: 4px 0 0 4px;
     -o-border-radius: 4px 0 0 4px;
}
.popupform .slider-popup {
    margin: 0;
    padding: 10px 10px 10px 40px;
    width: 100%;
    background: #fff;
    border: 1px solid #ECECEC;
    color: #000;
    font-size: 14px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.form-holder {
    position: relative;
    display: block;
    margin-bottom: 10px;
    width: 100%;
}
.form-holder > img {
    position: absolute;
    height: 15px;
    left: 13px;
    top: 11px;
}
 .popupform select {
     color: #666;
 }
 .popupform textarea {
     height: 100px;
     resize: vertical;
 }
 .popupform .popupform1 img {
     float: left;
     margin: 0 5px 0 0;
     height: 37px;
 }
 .popupform .popupform1 .chapcha {
     width: 58%;
 }
 .button-pu {
     margin: 2px 0 0 4px;
     padding: 4px 6px;
     background: #000;
     border: none;
     color: #a0b03b;
     cursor: pointer;
     text-transform: uppercase;
     font-size: 11px;
     font-weight: 700;
 }
 .button-pu:hover {
     margin: 2px 0 0 4px;
     padding: 4px 6px;
     background: none repeat scroll 0 0 #556201;
     border: none;
     color: #fff;
 }
 .clo {
     margin: 10px 0 15px;
     padding: 0;
     text-align: left;
     float: left;
     width: 100%
 }
 .clo img {
     margin: 0;
     padding: 0;
     float: left
 }
 .clo h1 {
     color: #181818;
     float: right;
     font-size: 14px;
     line-height: 16px;
     margin: 1px 0 0;
     padding: 0;
     text-transform: none;
     width: 224px
 }
 .clo h1 span {
     margin: 0;
     padding: 0;
     color: #ff0202
 }
 .clo h5 {
     color: #181818;
     float: right;
     font-size: 14px;
     line-height: 16px;
     margin: 1px 0 0;
     padding: 0;
     text-transform: none;
     width: 224px
 }
 .clo h5 span {
     margin: 0;
     padding: 0;
     color: #ff0202
 }
 .clo h6 {
     color: #181818;
     float: right;
     font-size: 14px;
     line-height: 16px;
     margin: 1px 0 0;
     padding: 0;
     text-transform: none;
     width: 224px
 }
 .clo h6 span {
     margin: 0;
     padding: 0;
     color: #ff0202
 }
 .contact-now {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%;
     background: #e0e0e0
 }
 .contact-now-inner {
     margin: 0 auto;
     padding: 20px 0;
     width: 90%
 }
 .contact-now h1 {
     margin: 0 0 8px;
     padding: 0;
     font-weight: 400;
     font-size: 21px;
     color: #fff;
     text-transform: uppercase
 }
 .contact-now span {
     color: #181818;
     font-size: 16px;
     margin: 0 0 2px;
     padding: 0 0 0 30px;
     text-transform: uppercase;
     float: left;
     text-align: left;
     width: 100%;
     height: 23px;
 }
 .contact-now span strong {
     color: #fc4d00;
     font-size: 14px;
     margin: 0;
     padding: 0;
     font-weight: 600;
     float: right
 }
 .contact-now span a {
     color: #181818;
     font-size: 16px;
     text-transform: none
 }
 .contact-now span a:hover {
     color: #fc4d00
 }
 .contact-now .contact-now-phone {
     background: url(../images/chat-now-phone.png) no-repeat scroll left 4px
 }
 .contact-now .contact-now-mgs {
     background: url(../images/chat-now-mgs1.png) no-repeat scroll left 6px;
     margin: 6px 0 0;
     padding: 0 0 0 33px
 }

 .closed1 {
     background: none repeat scroll 0 0 #1b9dd3;
     border-radius: 5px 5px 5px 5px;
     color: #fff;
     font-size: 11px;
     margin: 0;
     padding: 3px 7px
 }
 .closed1:hover {
     background: #0f8ec3;
     border-radius: 5px 5px 5px 5px;
     color: #fff;
     font-size: 11px;
     margin: 0;
     padding: 3px 7px
 }
 .display-status {
    min-height: 292px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
 }
 #sucmsg {
    font-size: 20px;
    margin: 0;
    color: #44ac00;
    text-align: center;
    font-weight: 600;
 }
 .midd-result {
     width: 630px;
     float: left;
     line-height: 20px;
     text-align: justify;
     margin-bottom: 10px
 }
 .midd-result ul {
     margin: 10px 0 0 10px;
     padding: 0
 }
 .midd-result li {
     background: url(../images/bullet.png) no-repeat scroll 0 7px transparent;
     list-style-type: none;
     padding-left: 15px
 }
 .midd-result li a {
     margin: 0;
     padding: 0;
     color: #464646
 }
 .midd-result li a:hover {
    margin: 0;
    padding: 0;
    color: #cb1402
 }
.form-table {
    margin: 0;
    padding: 10px;
    position: fixed;
    right: 30px;
    bottom: 0;
    width: 330px;
    background-color: #fff;
    outline: 1px solid #ddd;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    z-index: 1111;
}
.form-table.open {
    transform: translatey(0);
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -ms-transform: translatey(0);
    -o-transform: translatey(0);
}
.form-table.open .close_btn {
    font-size: 30px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    align-items: center;
    right: -20px;
    top: -22px;
    background-color: #fff;
    padding: 0;
}
.popupform {
    border: 0;
    margin: 0;
    padding: 0;
}
.popupform .popup-header {
    background: linear-gradient(180deg, #FF6E00 0%, rgba(255, 110, 0, 0.71) 100%);
    margin: -10px -10px 10px -10px;
    padding: 10px 10px 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}
.popupform .popup-header img {
    width: 80px;
}
.popupform .popup-header p {
    color: #fff;
    font-size: 13px;
    line-height: 17px;
    width: calc(100% - 90px);
    width: -o-calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
}
.form-table .popupform .button-pu {
    margin: 0;
    padding: 8px 0;
    background: linear-gradient(180deg, #FF6E00 0%, rgba(255, 110, 0, 0.71) 100%);
    color: #fff;
    width: 100%;
    font-size: 16px;
    text-align: center;
    border-radius: 4px ;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    text-transform: capitalize;
    font-weight: 600;
}
.form-table .popupform .button-pu:hover {
    background: linear-gradient(180deg, rgba(255, 110, 0, 0.71) 0%, #FF6E00 100%);
}
.form-table .popupform .button-pu img {
    width: 15px;
    margin-left: 4px;
    vertical-align: middle;
}
.msg-icon {
    margin: 7px 0 0;
    padding: 0;
}
select:focus,
textarea:focus {
    background: 0 0
}
.close_btn {
    margin: 0;
    padding: 8px 15px 10px 15px;
    position: absolute;
    top: -38px;
    display: inline-block;
    border: none;
    background-color: #FF6E00;
    color: #fff;
    font-size: 17px;
    right: 10px;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
    cursor: pointer;
}
 .close_btn1 {
     left: -38px
 }
 td,
 th {
     padding: 5px;
     text-align: center;
     vertical-align: middle
 }
 .chat_popup {
     background: #4a0;
     padding: 0 0 12px 26px
 }
 .chat_popup img {
     margin: 0;
     padding: 0;
     float: left
 }
 .msg-icon_chat {
     float: left;
     margin: 7px 0 0;
     padding: 0
 }
 .clo_chat {
     float: left;
     margin: 0;
     padding: 9px 0 17px 9px;
     text-align: left;
     width: 100%
 }
 .clo_chat img {
     float: left;
     margin: 0 10px 0 0;
     padding: 0
 }
 .clo_chat h1 {
     color: #fff;
     font-size: 14px;
     font-weight: 400;
     line-height: 16px;
     text-transform: uppercase;
     float: left;
     margin: 0;
     padding: 0;
     width: 78%
 }
 .clo_chat b {
     color: #fff;
     font-size: 14px;
     font-weight: 400;
     line-height: 16px;
     text-transform: uppercase;
     float: left;
     margin: 0;
     padding: 0;
     width: 78%
 }
 .chat-now-top {
     margin: 0;
     padding: 0;
     float: left;
     background: #fff url(../images/chat-now-bg.jpg) no-repeat;
     width: 332px;
     height: 460px;
 }
 .chat-now-top b {
     color: #fff;
     font-size: 14px;
     font-weight: 400;
     line-height: 16px;
     text-transform: uppercase;
     float: left;
     margin: 0;
     padding: 0;
     width: 78%
 }
 .chat-now-top-inner {
     margin: 0 auto;
     padding: 0;
     width: 90%;
     display: table
 }
 #waittext {
     color: #000
 }
 .country1 .select-wrapper {
     float: left;
     display: inline-block;
     border: none;
     background: #fff url(../images/arrow-red.jpg) no-repeat scroll right 17px;
     cursor: pointer;
     position: relative
 }
 .country1 .select-wrapper em {
     width: 93%;
     font-style: normal;
     text-transform: none
 }
 .country1 .select-wrapper,
 .select-wrapper select {
     border: 1px solid #ccc;
     padding: 9px 0 9px 4px;
     margin: 0;
     width: 100%;
     font-size: 15px;
     border-radius: 3px
 }
 .country1 .select-wrapper:hover {
     background: #fff url(../images/arrow-red.jpg) no-repeat scroll right 17px
 }
 .country1 .select-wrapper .holder {
     display: block;
     margin: 1px 18px 0 0;
     white-space: nowrap;
     overflow: hidden;
     cursor: pointer;
     position: relative;
     z-index: 0;
     color: #999;
     font-size: 13px
 }
 .country1 .select-wrapper select {
     margin: 0;
     padding: 0;
     position: absolute;
     z-index: 2;
     cursor: pointer;
     outline: 0;
     opacity: 0;
     -khtml-opacity: 0;
     -moz-opacity: 0;
     left: 0;
     width: 100%;
     color: #999
 }
 .country1 .select-wrapper select option {
     margin: 0;
     padding: 4px 0
 }
 .country1 .select-wrapper .holder {
     text-align: left
 }
 .country1.chapcha-code {
     width: 100%
 }
 .footer-dmca {
     margin: 10px 0 4px;
     padding: 0;
     float: left;
     width: 100%;
     text-align: center;
 }
 .footer-dmca-before,
 .footer-dmca-after {
    position: relative;
 }
 .footer-dmca a {
     margin: 0 auto;
     padding: 0;
     float: none
 }
 .footer-dmca p:last-child {
     margin:8px 0 0 0;
     text-align: center;
}
 .footer-dmca a img {
     margin: 0 auto;
     padding: 0;
     float: none
 }
 ul.latestblogs li:before {
     content: '';
     width: 6px;
     height: 6px;
     background-color: #FF6E00;
     position: absolute;
     border-radius: 50%;
     left: 0;
     top: 7px
 }
 .bott-block2 h6.page-heading {
     margin-top: 3px
 }
 @media screen and (min-width:1px) and (max-width:1023px) {
     .navbar-nav>li:hover .sub-menu {
         display: none
     }
     .navbar-nav>li:hover .sub-menu li:hover .sub-menu1 {
         display: block;
         position: relative;
         left: 0;
         box-shadow: none;
         min-width: 100%
     }
     .navbar-nav>li:hover .sub-menu li:hover .sub-menu1 li a {
         text-align: center;
         padding: 10px 0
     }
     .navbar-nav>.open>.dropdown-menu {
         display: block !important;
         position: relative;
         min-width: 100%;
         box-shadow: none;
         overflow-x: auto;
         overflow-y: scroll;
         height: 120px
     }
     .navbar-nav>.open>.dropdown-menu li {
         margin: 0;
         padding: 0;
         float: left;
         width: 98.5%
     }
     .navbar-nav>.open>.dropdown-menu li a {
         margin: 0;
         padding: 0;
         float: left;
         width: 100%;
         min-width: 100%
     }
     .navbar-nav>.open>.dropdown-menu>li>a {
         text-align: center;
         padding: 10px 0
     }
     .footer-address-img img {
         max-width: 100%
     }
 }
 @media screen and (min-width:1px) and (max-width:414px) {
     .bott-block1 {
         margin: 0 0 15px
     }
     .bott-block2 {
         margin: 0 0 15px
     }
     .bott-block2 .social h6.page-heading {
         margin: 0 0 15px
     }
     .bott-block3 {
         margin: 0 0 15px
     }
     .bott-block3 h6.page-heading {
         margin: 0
     }
     .bott-block4 {
         float: left
     }
     .bott-part2 {
         padding-bottom: 20px
     }
     .social a img {
         width: 30px
     }
 }
 @media screen and (min-width:1px) and (max-width:400px) {
     .footer-slogen img {
         width: 100%
     }
 }
 @media screen and (min-width:1px) and (max-width:350px) {
     .bott-block4 ul li .footer-blog-right {
         width: 190px
     }
 }
 @media screen and (min-width:768px) and (max-width:1023px) {
     .menu-part-submenu {
         display: block
     }
     .navbar-toggle {
         display: none
     }
     .navbar-header {
         float: left !important;
         margin: 0 !important
     }
     .bott-block3 {
         width: 300px
     }
 }
 @media screen and (max-width:767px) {
     .menu-part-submenu {
         display: block
     }
     .navbar-toggle {
         display: none
     }
     .navbar-header {
         float: left !important;
         margin: 0 !important
     }
 }
 .menu-part-submenu {
     display: none
 }
 .topnav1 {
     background-color: #fff;
     float: left;
     width: 100%;
     padding: 0;
     display: none
 }
 .topnav1 ul {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .topnav1 ul li {
     margin: 0;
     padding: 0;
     float: left;
     width: 100%;
     display: block
 }
 .topnav1 a {
     float: left;
     display: block;
     color: #000;
     text-align: center;
     padding: 8px 0;
     text-decoration: none;
     font-size: 15px
 }
 .topnav1 ul li.parent:hover a.uber-taxi-c {
     color: #fa6903
 }
 .topnav1 ul li.parent ul li a {
     color: #666;
     padding-left: 10px
 }
 .topnav1 ul li.parent ul li a:hover {
     color: #fa6903
 }
 .topnav1 ul li.parent1:hover a.uber-taxi-c {
     color: #fa6903
 }
 .topnav1 ul li.parent1 ul li a {
     color: #666;
     padding-left: 10px
 }
 .topnav1 ul li.parent1 ul li a:hover {
     color: #fa6903
 }
 .topnav1 a:hover {
     color: #000;
     color: #fa6903
 }
 .topnav1 .icon {
     display: none
 }
 @media screen and (min-width:1px) and (max-width:1024px) {
     .topnav1 ul {
         display: none
     }
     .topnav1 a.icon {
         background: #333;
         border-radius: 6px;
         color: #fff;
         display: block;
         float: right;
         font-size: 30px;
         padding: 0 9px 2px;
         position: fixed;
         right: 10px;
         top: 15px
     }
     .topnav1 a.icon img {
         vertical-align: baseline
     }
 }
 @media screen and (min-width:1px) and (max-width:1024px) {
     .topnav1 {
         display: block
     }
     .topnav1.responsive {
         position: relative
     }
     .topnav1.responsive a.icon {
         position: fixed;
         right: 10px;
         top: 15px
     }
     .topnav1.responsive ul {
         display: block
     }
     .topnav1.responsive ul li {
         display: block;
         text-align: left;
         border-bottom: 1px solid #ddd
     }
     .topnav1.responsive ul li a {
         text-align: left
     }
     .topnav1.responsive ul li a.menu1-active {
         color: #ff6e00
     }
     .topnav1.responsive ul li.parent {
         background: url(../images/menu-res-icon.jpg) no-repeat scroll right 15px
     }
     .topnav1.responsive ul li.parent li:last-child {
         border: none
     }
     .topnav1 ul.sub-nav {
         display: none
     }
     .topnav1.responsive ul li.parent1 {
         background: url(../images/menu-res-icon.jpg) no-repeat scroll right 15px
     }
     .topnav1.responsive ul li.parent1 li:last-child {
         border: none
     }
     .topnav1 ul.sub-nav1 {
         display: none
     }
     .topnav1 ul.visible {
         display: block
     }
 }
 .footer-links .row {
     margin-bottom: 15px
 }
 .footer-links .col-sm-2 p {
     margin-bottom: 15px;
     font-weight: 700;
     color: #000;
     font-size: 22px;
     font-family: 'Exo 2'
 }
 .list-of-links {
     margin-left: 20px
 }
 .list-of-links li {
     color: #fc4d00;
     margin-bottom: 10px;
     display: list-item
 }
 .list-of-links li a {
     color: #383838;
     font-size: 15px;
     font-weight: 500;
     font-family: 'Exo 2'
 }
 .list-of-links li a:hover {
     color: #ff6f02
 }
 .list-of-links.lst {
     margin-top: 41px
 }
 footer .flex-rowmain {
     display: flex;
     flex-wrap: wrap;
     justify-content: space-between;
     margin-bottom: 0
 }
 footer .flex-row {
     display: flex
 }
 footer .flex-row .list-of-links {
     margin-right: 40px
 }
 footer .footer-col-title {
     font-size: 22px;
     font-weight: 700
 }
 .footer-column {
     margin-bottom: 20px
 }
 .tab-footer {
     margin: 0 0 30px;
     padding: 0;
     overflow: hidden;
     border-top: 1px solid #ddd;
     float: left;
     width: 100%;
     display: none
 }
 .tab-footer button {
     float: left;
     border: none;
     outline: 0;
     cursor: pointer;
     padding: 14px 16px;
     transition: .3s;
     font-size: 17px;
     border-bottom: 1px solid #ddd;
     text-align: left
 }
 .tab-footer button b {
     margin: 0 0 0 15px;
     font-weight: 500
 }
 .tab-footer button:hover {
     color: #fc4d00
 }
 .tab-footer button.active {
     color: #fc4d00
 }
 .tab-footer .tabcontent {
     display: none;
     padding: 6px 0;
     border: 1px solid #ccc;
     border-top: none;
     float: left;
     width: 100%
 }
 .tab-footer .tabcontent ul {
     margin: 10px 0 0;
     padding: 0;
     float: left;
     width: 100%
 }
 .tab-footer .tabcontent ul li {
     margin: 0 0 12px;
     padding: 0;
     float: left;
     width: 100%;
     display: list-item;
     color: #fc4d00
 }
 .tab-footer .tabcontent li a {
     margin: 0;
     padding: 0;
     color: #383838;
     font-size: 15px
 }
 .tab-footer .tabcontent li a:hover {
     color: #fc4d00
 }
 .tab-footer .tablinks {
     background: url(../images/plush.jpg) no-repeat scroll top 17px right 12px
 }
 .tab-footer .active {
     background: url(../images/plush-down.jpg) no-repeat scroll top 17px right 12px
 }
 @media screen and (min-width:1px) and (max-width:767px) {
     .pricing-btn {
         position: fixed;
         top: 363px;
         right: 0;
         z-index: 999
     }
 }
 @media screen and (min-width:1px) and (max-width:414px) {
     footer .flex-row {
         flex-direction: column
     }
     .tab-footer button {
         width: 100%;
         padding: 15px 0;
         float: left
     }
     .bott-cnt b.page-heading {
         font-size: 31px
     }
     .tab-footer .tabcontent ul li {
         margin: 0 0 10px 27px;
         width: 90%
     }
 }
 .navbar-inverse .navbar-nav .go-jek-menu a {
     color: #000
 }
 ul,
 ol {
     margin: 0;
     padding: 0;
     list-style-type: none
 }
 ul.latestblogs li {
     position: relative;
     padding: 0 0 0 9px
 }
 .list-of-links li::marker {
     display: none
 }
 .list-of-links li:before {
     content: '';
     position: absolute;
     width: 6px;
     height: 6px;
     background-color: #FF6E00;
     border-radius: 50%;
     left: -18px;
     top: 7px
 }
 .list-of-links li {
     position: relative;
     text-align: left
 }